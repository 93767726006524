.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
  font: 14px/1.21 "Helvetica Neue", arial, sans-serif !important;
}

.modal-window > div {
  width: 560px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
}

.modal-header {
  display: flex;
  margin-bottom: 35px;
  justify-content: space-between;
}

.question-text,
.modal-title-text {
  margin: 0;
}

.modal-btn {
  background-color: transparent;
  border: 0;
  color: #aaa;
  font-size: 80%;
  cursor: pointer;
  text-decoration: underline;
}

.modal-btn:hover {
  color: #7c7c7c;
}

.modal-footer {
  text-align: right;
}

.previous-card-button {
  margin-right: 10px;
}
