.btn-primary {
  background-color: #ccc;
  border: none;
  padding: 12px;
  border-radius: 0.3rem;
}

@media only screen and (max-width: 670px) {
  .modal-window > div {
    width: 80%;
  }
  .question-text {
    font-size: 18px;
  }
  .correct,
  .wrong {
    font-size: 14px !important;
  }
}
