.quiz-header {
  margin-bottom: 20px;
}

.question-text {
  font-weight: normal;
}

.quiz-body {
  max-height: 50vh;
  overflow-y: auto;
}

.choices-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 30px;
}

.choice-list-item {
  background-color: aliceblue;
  padding: 20px;
  margin-bottom: 14px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 0 !important;
}

choice-list-item:last-of-type {
  margin: 0;
}

.choice-list-item:hover {
  background-color: #cbe5fc;
}

.choice-text {
  margin: 0;
}

.quiz-container {
  position: relative;
}

.quiz-footer {
  text-align: center;
  margin-top: 20px;
}

.quiz-footer h3 {
  font-size: 20px !important;
  margin: 0;
}

.selected {
  background-color: #cbe5fc;
}

.disabled {
  pointer-events: none;
}

.correct {
  color: #27ae60;
}

.wrong {
  color: #e74c3c;
}
